import { Button } from "antd";
import styled from "styled-components";

export const MainWrapper = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;


export const StyledButton = styled(Button)`
  height: 40px;
  background-color: rgb(18, 235, 207);
  color: #000000;
  border: 1px solid black;
  border-radius: 12px;
  font-weight: bolder;
  font-size: 1rem;
`;

export const LottieContainer = styled.div`
  width: 60%;
  height: auto;
  margin: auto;
  margin-top: 8%;
`;