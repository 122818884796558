import { ISummaryPage } from "../interfaces";
import { useContext, useEffect } from "react";
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient";
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys";
import { MainWrapper, LottieContainer } from "./styled";
import { AppStore } from '../../../app-store/store';
import Lottie from 'lottie-react';
import summaryLottie from '../../../assets/Lottie/CoinConfetti.json';
import { CustomizedButton } from "../welcome-page/styled";


export enum SummaryPageSelection {
    playAgain = "playAgain",
    exit = "exit"
}

export interface SummaryScreenInput {

}

export const SummaryScreen: React.FC<ISummaryPage> = ({ metadata, theme, input, onPageComplete }) => {

    const { userSession } = useContext(AppStore)

    useEffect(() => {
        Mixpanel.track(AnalyticsKeys.SummaryScreen, {})
    }, []);

    const tryAgaintButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Play Again"
        })
        onPageComplete(SummaryPageSelection.playAgain)
    }

    const exitButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Exit"
        })
        onPageComplete(SummaryPageSelection.exit)
    }

    return (
        <MainWrapper>
            {
                summaryLottie !== null &&
                <LottieContainer id="lottie-container">
                    <Lottie animationData={summaryLottie} />
                </LottieContainer>
            }
            {
                <p>You have completed the experience!</p>
            }
            <CustomizedButton primaryColor={theme.primaryColor} secondaryColor={theme.secondaryColor} onClick={() => tryAgaintButtonTapped()}>
                Try again
            </CustomizedButton>
        </MainWrapper>
    );
}