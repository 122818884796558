import styled from "styled-components";

export const MainWrapper = styled.div<{
  backgroundImageUrl?: string;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background-image: ${({ backgroundImageUrl }) =>
    backgroundImageUrl ? `url(${backgroundImageUrl})` : "none"};
  background-size: cover;
  background-position: center;
  text-align: center;
`;

export const ButtonsAndNotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically if needed */
  width: 100%; /* Full width */
  position: absolute; /* Keep this if you want the entire container to be at a specific place */
  bottom: 5%; /* Adjust as necessary */
  gap: 10px;
`;

export const WelcomeScreenButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 10px; /* Space between buttons */
`;

export const NotesContainer = styled.div`
  overflow-y: auto; /* Enable vertical scrolling if needed */
  width: 100%; /* Ensure it takes full width */
  height: 30px; /* Set a fixed height */
`;

export const CustomizedButton = styled.button<{
  primaryColor: string;
  secondaryColor: string;
  disabled?: boolean;
}>`
  background: ${({ primaryColor, secondaryColor, disabled }) =>
    disabled
      ? "#cccccc"
      : `linear-gradient(to right, ${primaryColor}, ${secondaryColor})`};
  color: ${({ primaryColor, disabled }) =>
    disabled
      ? "#666666"
      : primaryColor.toLowerCase() === "#ffffff"
        ? "black"
        : "white"};
  width: 130px;
  height: 40px;
  border-radius: 10px;
  box-shadow: ${({ disabled }) =>
    disabled
      ? "none"
      : "0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11)"};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-weight: bold;
`;

export const Note = styled.p`
  display: block;
  margin: 0;
  padding: 0;
  font-size: 11px;
`;

export const Image = styled.img`
  width: 200px;
  height: 200px;
`;
