import { LocalizationKeys } from "./LocalizationKeys";

export const ArabicLocalizationMap: Record<LocalizationKeys, string> = {
    [LocalizationKeys.IntroStartButton]: "ابدأ",
    [LocalizationKeys.IntroPageLoadingText]: "...جاري تحميل المحتوى",
    [LocalizationKeys.RegistrationSubmitButton]: "ارسال",
    [LocalizationKeys.RegistrationSkipButton]: "تخطى",
    [LocalizationKeys.RegistrationFullName]: "*الاسم الكامل",
    [LocalizationKeys.RegistrationDisplayName]: "*كنية",
    [LocalizationKeys.RegistrationPhoneNumber]: "*رقم الهاتف",
    [LocalizationKeys.RegistrationEmail]: "*بريد الكتروني",
    [LocalizationKeys.RegistrationConsent]: "أرغب في مشاركة معلومات التسجيل للحصول على عروض وتخفيضات",
    [LocalizationKeys.RegistrationPrizesButton]: "جوائز",
    [LocalizationKeys.RegistrationSelectImageButton]: "حمّل صورة",
    [LocalizationKeys.WelcomeContinueButton]: "استمرار",
    [LocalizationKeys.WelcomePrizesButton]: "جوائز",
    [LocalizationKeys.ExperiencePageStartButton]: "ابدا",
    [LocalizationKeys.ExperiencePageLoadingText]: "...جاري تحميل محتوى اللعبة",
    [LocalizationKeys.ExitModalText]: "هل أنت متأكد أنك تريد الخروج؟",
    [LocalizationKeys.ExitModalOkButton]: "نعم",
    [LocalizationKeys.ExitModalCancelButton]: "إلغاء",
    [LocalizationKeys.PostGameRegistrationButton]: "تسجيل",
    [LocalizationKeys.PostGameRegistrationText]: "يجب التسجيل لترى قائمة النتائج",
    [LocalizationKeys.PostGameTryAgainButton]: "حاول ثانية",
    [LocalizationKeys.SelectionScreenDoneButton]: "!ابدأ باللعب"
};