import * as THREE from 'three';
import { ARManager } from "../../Managers/ARManager"
import { getRandomFloat, resetTranslation } from "../../utils"
import { TriggerManager, pubsub } from '../../TriggerAction/TriggerManager';
import { Action, ActionType } from '../../TriggerAction/Actions';
import { BrandThemeManager } from '../../Managers/BrandThemeManager';
import { Activity, ExperienceObject, FullExperience, GeneralRule } from '../../ExperienceObjects/ExperienceObject';
import { ObjectAnimation, ObjectRandomStraightMovements, ObjectStraightMovement, SpinParams } from '../../Managers/ObjectActionManager';
// import { ExperienceARAnalytics } from './Managers/ARAnalyticsManager';
import { ITemplatesBasics } from '../TemplatesInterface'
import { ExperienceAnalyticsManager } from '../../Managers/ExperienceAnalyticsManager';
import { SoundManager } from '../../Managers/SoundManager';
import { LottieNames, SessionStatus } from '../../components/pages';
import { ICustomExperience } from '../../TriggerAction/Config';
import { UIObjectesRendererDelegate } from '../../components/TemplatesRenderer/TemplateUIObjectsRenderer';

THREE.Cache.enabled = true

interface IPlatformManager extends ITemplatesBasics {
    triggerManager: TriggerManager;
    ARManager: ARManager;
    // UIManager: UIManager;
    brandThemeManager: BrandThemeManager | null;
    soundManager?: SoundManager;
    experienceAnalyticsManager: ExperienceAnalyticsManager;

    fullExperience: FullExperience | null;
    rawExperiencesByName: Record<string, ExperienceObject>;
    experiences: Record<string, ExperienceObject>;
    generalActivities: Record<string, Activity>;
    experiencesWithTrigger: Record<string, string[]>;

    // showInstructionsLottie(lottieName: LottieNames | null): void
    ARSessionStatusChange: ((status: SessionStatus) => void)
}

export class PlatformManager implements IPlatformManager {
    triggerManager: TriggerManager;
    ARManager: ARManager;
    // UIManager: UIManager;
    brandThemeManager: BrandThemeManager | null;
    soundManager?: SoundManager;
    experienceAnalyticsManager: ExperienceAnalyticsManager;

    fullExperience: FullExperience | null;
    rawExperiencesByName: Record<string, ExperienceObject>;
    experiences: Record<string, ExperienceObject>;
    generalActivities: Record<string, Activity>;
    experiencesWithTrigger: Record<string, string[]>;
    // showInstructionsLottie: ((lottieName: LottieNames | null) => void) | null = null
    ARSessionStatusChange: ((status: SessionStatus) => void) | null = null
    rendererRef?: UIObjectesRendererDelegate | null


    constructor(fullExperience: FullExperience | null,
        triggerManager: TriggerManager,
        ARManager: ARManager,
        // UIManager: UIManager,
        soundManager: SoundManager,
        brandThemeManager: BrandThemeManager,
        experienceAnalyticsManager: ExperienceAnalyticsManager,
        ARSessionStatusChange: ((status: SessionStatus) => void),
        // showInstructionsLottie: ((lottieName: LottieNames | null) => void) | null = null
    ) {

        this.fullExperience = fullExperience
        this.triggerManager = triggerManager
        this.ARManager = ARManager
        // this.ARManager.sessionEndedCallback = this.cleanup.bind(this)
        // this.UIManager = UIManager
        this.soundManager = soundManager
        this.brandThemeManager = brandThemeManager
        // this.brandThemeManager = null;
        this.experienceAnalyticsManager = experienceAnalyticsManager
        this.rawExperiencesByName = {};
        this.experiences = {};
        this.generalActivities = {};
        this.experiencesWithTrigger = {} // The instantiated experience IDs which have a given trigger
        // this.showInstructionsLottie = showInstructionsLottie
        this.ARSessionStatusChange = ARSessionStatusChange
    }

    setRendererRef(ref: UIObjectesRendererDelegate | null) {
        this.rendererRef = ref
    }

    static async loadCustomExperience(json: ICustomExperience | null): Promise<FullExperience> {
        var dataDict = json

        try {
            const fullExperience = new FullExperience(dataDict)
            await fullExperience.loadData();
            return fullExperience

        } catch (err) {
            console.log(err)
            return null
        }
    }

    async launchAR() {
        await this.ARManager.initXR(["local-floor", "hit-test"])
        this.ARManager.startAR()
        this.experienceAnalyticsManager.setExperienceStartTime()
    }

    cleanup() {
        console.log("PlatformManager - Cleaning up...")
        this.ARManager.cleanup()
        this.triggerManager.cleanup()
        // this.UIManager.cleanup()
        this.rendererRef?.cleanup()
        this.brandThemeManager?.cleanup()
        this.soundManager?.cleanup()

        this.rawExperiencesByName = {};
        this.experiences = {};
        this.generalActivities = {};
        this.experiencesWithTrigger = {}
        // document.body.classList.remove('stabilized');
    }

    getARAnalytics() {
        return this.ARManager.getARAnalytics()
    }

    prepareFullAnalytics(sessionStatus: SessionStatus | null = null): Record<string, any> {
        if (sessionStatus !== null) {
            this.experienceAnalyticsManager.changeSessionStatus(sessionStatus)
        }

        // TODO: Consider returning this or summarizing it as it is too big
        let arAnalytics = this.ARManager.getARAnalytics()
        if (arAnalytics !== null) {
            let arAnalyticsDict = arAnalytics.toDictionary()
            this.experienceAnalyticsManager.integrateAdditionalAnalytics("ARAnalytics", arAnalyticsDict)
        }

        let customAnalytics = this.getCustomExperienceAnalytics()
        if (customAnalytics !== null) {
            let [customExperienceAnalytics, key] = customAnalytics
            if (customExperienceAnalytics !== null && key !== null) {
                this.experienceAnalyticsManager.integrateAdditionalAnalytics(key, customExperienceAnalytics)
            }
        }

        let analyticsDict = this.experienceAnalyticsManager.getAnalyticsDict()

        return analyticsDict
    }

    getCustomExperienceAnalytics(): [Record<string, any>, string] | null {
        return null
    }

    ///////////// TRIGGER ACTION PARSING ////////////
    startExperience() {
        if (this.fullExperience) {
            // console.log("PlatformManager - Starting full experience", this.fullExperience)
            for (const experienceObject of this.fullExperience.experiences) {
                this.addRawExperience(experienceObject)
            }

            for (const generalRule of this.fullExperience.generalRules) {
                this.addGeneralRule(generalRule)
            }

            for (const uiObject of this.fullExperience.UIObjects) {
                this.rendererRef?.placeUIObject(uiObject)
            }

            this.triggerManager.experienceStarted()
        }
    }

    addRawExperience(experienceObject: ExperienceObject) {
        if (this.rawExperiencesByName[experienceObject.name]) {
            throw new Error(`Experience with name ${experienceObject.name} already exists`);
        }

        this.rawExperiencesByName[experienceObject.name] = experienceObject

        for (const activity of experienceObject.activities) {
            const trigger = activity.trigger
            pubsub.subscribe(trigger.description, experienceObject.name, this.executeActionForTrigger.bind(this))
            this.triggerManager.parseActivity(activity, experienceObject)

            for (const action of activity.actions) {
                if (action.actionType == ActionType.playSound) {
                    this.soundManager?.createSound(action.params.soundtrackName, action.params.soundtrackUrl)
                }
            }
        }
    }

    addGeneralRule(generalRule: GeneralRule) {
        if (this.generalActivities[generalRule.activity.id]) {
            throw new Error(`General rule with id ${generalRule.activity.id} already exists`);
        }
        // Subscribe to this trigger
        const trigger = generalRule.activity.trigger
        pubsub.subscribe(trigger.description, generalRule.activity.id, this.executeActionForTrigger.bind(this))

        for (const action of generalRule.activity.actions) {
            if (action.actionType == ActionType.playSound) {
                this.soundManager?.createSound(action.params.soundtrackName, action.params.soundtrackUrl)
            }
        }

        this.generalActivities[generalRule.activity.id] = generalRule.activity
        this.parseInstantiatedExperienceActivity(generalRule.activity, null)
    }

    parseInstantiatedExperienceActivities(experienceObject: ExperienceObject) {
        // console.log("Parsing instantiated experience activities...")
        for (const activity of experienceObject.activities) {
            this.parseInstantiatedExperienceActivity(activity, experienceObject)
        }
    }

    parseInstantiatedExperienceActivity(activity: Activity, experience: ExperienceObject | null) {
        this.triggerManager.parseActivity(activity, experience)

        if (experience) {
            const experienceID = experience.id
            const triggerID = activity.trigger.triggerID
            if (this.experiencesWithTrigger[triggerID]) {
                if (!this.experiencesWithTrigger[triggerID].includes(experienceID)) {
                    this.experiencesWithTrigger[triggerID].push(experienceID)
                }
            } else {
                this.experiencesWithTrigger[triggerID] = [experienceID]
            }
        }
    }

    instantiateExperience(rawExperience: ExperienceObject) {
        if (!this.rawExperiencesByName[rawExperience.name]) {
            throw new Error(`Raw experience ${rawExperience.name} is invalid!`)
        }

        if (this.rawExperiencesByName[rawExperience.name].maxExecutions <= 0) {
            console.log(`Raw experience ${rawExperience.name} completed all executions`)
            return
        } else {
            this.rawExperiencesByName[rawExperience.name].maxExecutions -= 1
        }

        const experience = rawExperience.instantiateExperience()
        this.parseInstantiatedExperienceActivities(experience)
        this.experiences[experience.id] = experience
    }

    // Remove an instantiated experience. Should be called when an object is killed (since each experience has exactly 1 object)
    killInstantiatedExperience(experienceID: string | undefined) {

        if (experienceID && experienceID in this.experiences) {
            let experience = this.experiences[experienceID]

            for (const activity of experience.activities) {
                const filteredExperiences = this.experiencesWithTrigger[activity.trigger.triggerID].filter((id) => id != experienceID)
                this.experiencesWithTrigger[activity.trigger.triggerID] = filteredExperiences
            }
            delete this.experiences[experienceID]
        }
    }

    instantiateExperienceIfNeeded(triggerID: string, rawExperienceName: string) {
        // for (const [rawExperienceName, rawExperience] of Object.entries(this.rawExperiencesByName)) {
        if (this.rawExperiencesByName[rawExperienceName]) {
            const rawExperience = this.rawExperiencesByName[rawExperienceName]
            for (const activity of rawExperience.activities) {
                // console.log("Trying to instantiate", activity.trigger.triggerID, triggerID)
                if (activity.trigger.triggerID == triggerID) {
                    for (const action of activity.actions) {
                        if (action.isBringToLifeAction() && rawExperience.maxExecutions > 0) {
                            this.instantiateExperience(rawExperience)
                            break;
                        }
                    }
                }
            }
        }
    }

    executeGeneralActivities(triggerID: string, activityID: string, data: Record<string, any>) {
        for (const [id, activity] of Object.entries(this.generalActivities)) {
            if (activity.trigger.triggerID == triggerID && activity.id == activityID) {
                this.executeActivity(activity, null, data)
            }
        }
    }

    executeActionForTrigger(triggerID: string, experienceObjectName: string, data: Record<string, any>) {
        this.instantiateExperienceIfNeeded(triggerID, experienceObjectName)
        this.executeGeneralActivities(triggerID, experienceObjectName, data)

        const filteredExperienceIDs = this.experiencesWithTrigger[triggerID]
        if (filteredExperienceIDs) {
            for (const experienceID of filteredExperienceIDs) {
                const experience = this.experiences[experienceID]
                if (experience) {
                    for (const activity of experience.activities) {
                        if (activity.trigger.triggerID == triggerID) {
                            this.executeActivity(activity, experience.object, data)
                        }
                    }
                }
            }
        }
    }

    executeActivity(activity: Activity, object: THREE.Object3D | null, data: Record<string, any>) {
        if (activity.maxExecutions <= 0)
            return

        activity.maxExecutions -= 1
        for (const action of activity.actions) {
            // Switch cases for non-null object
            if (object) {
                switch (action.actionType) {
                    case ActionType.bringToLife:
                        if (action.params["loc"] == "triggerPos") {
                            if (data["transform"] instanceof THREE.Matrix4) {
                                const pose: THREE.Matrix4 = data["transform"]
                                this.ARManager.changeARObjectTransform(object, pose)
                            }
                        } else if (action.params["loc"] == "userPos") {
                            var positionInFront = this.ARManager.getUserPosition()
                            this.ARManager.changeARObjectPosition(object, positionInFront)
                        } else if (action.params["loc"] == "infront") {
                            var infrontParams = action.params.infrontParams
                            var squareParams = {
                                minX: infrontParams?.minX ?? -0.2,
                                maxX: infrontParams?.maxX ?? 0.2,
                                minY: infrontParams?.minY ?? -0.2,
                                maxY: infrontParams?.maxY ?? 0.1,
                                minZ: infrontParams?.minZ ?? -0.2,
                                maxZ: infrontParams?.maxZ ?? 0.2
                            }
                            var randomDisplacement = new THREE.Vector3(
                                getRandomFloat(squareParams.minX, squareParams.maxX),
                                getRandomFloat(squareParams.minY, squareParams.maxY),
                                getRandomFloat(squareParams.minZ, squareParams.maxZ)
                            )
                            var transformInfront = this.ARManager.getTransformInfronOfUser(new THREE.Vector3(0, 0, -0.5))
                            transformInfront.multiplyMatrices(transformInfront, new THREE.Matrix4().makeTranslation(randomDisplacement.x, randomDisplacement.y, randomDisplacement.z));
                            this.ARManager.changeARObjectTransform(object, transformInfront)
                        }

                        this.ARManager.addARObjectToScene(object)
                        break;
                    case ActionType.bringToLifeViaFS:
                        // this.UIManager.placeLottie(LottieNames.scanPlane)
                        this.rendererRef?.placeLottie(LottieNames.scanPlane)
                        this.ARManager.addARObjectToSceneViaFS(object, () => {

                        }, (transform) => {

                        })
                        break;
                    case ActionType.kill:
                        if (action.params.delay && action.params.delay > 0) {
                            const self = this;
                            setTimeout(function () {
                                self.ARManager.removeARObjectFromScene(object)
                                self.killInstantiatedExperience(object.userData.experienceID)
                            }, action.params.delay * 1000)
                        } else {
                            this.ARManager.removeARObjectFromScene(object)
                            this.killInstantiatedExperience(object.userData.experienceID)
                        }
                        break;
                    case ActionType.move:
                        const direction = new THREE.Vector3(action.params.x ?? 0, action.params.y ?? 0, action.params.z ?? 0);
                        const movementAction = new ObjectStraightMovement(direction, direction.length(), 20 * direction.length());
                        this.ARManager.addActionToObject(object, movementAction)
                        break;
                    case ActionType.moveFor:
                        const straightDirection = new THREE.Vector3(
                            action.params.x,
                            action.params.y,
                            action.params.z
                        ).normalize()
                        const controlledStraightMovements = new ObjectRandomStraightMovements(action.params.duration, action.params.durationPerMove, action.params.speedFactor, straightDirection);
                        this.ARManager.addActionToObject(object, controlledStraightMovements)
                        break;
                    case ActionType.moveRandom:
                        const randStraightMovements = new ObjectRandomStraightMovements(action.params.duration, action.params.durationPerMove, action.params.speedFactor);
                        this.ARManager.addActionToObject(object, randStraightMovements)
                        break;
                    case ActionType.moveRandomDirection:
                        const randDirection = new THREE.Vector3(
                            Math.random() * 2 - 1,
                            Math.random() * 2 - 1,
                            Math.random() * 2 - 1
                        ).normalize()
                        const randMovementAction = new ObjectStraightMovement(randDirection, randDirection.length(), action.params.duration);
                        this.ARManager.addActionToObject(object, randMovementAction)
                        break;
                    case ActionType.rotate:
                        if (action.params.angleY) {
                            this.ARManager.rotateObject(object, action.params.angleY)
                        }
                        break;
                    case ActionType.scale:
                        if (action.params.scale) {
                            this.ARManager.scaleARObject(object, action.params.scale)
                        }
                        break;
                    case ActionType.animate:
                        const animation = new ObjectAnimation(action.params);
                        if (animation) {
                            this.ARManager.addAnimationToObject(object, animation)
                        }
                        break;
                    case ActionType.shoot:
                        this.ARManager.shootObjectForward(object, 5, 3)
                        break;
                    case ActionType.replaceObject:
                        if (action.params.replacementObjectId && this.fullExperience) {
                            const newObjectId = action.params.replacementObjectId
                            const foundExperience = this.fullExperience.experiences.find(exp => exp.name === newObjectId);

                            if (foundExperience) {
                                this.ARManager.replaceARObjectWithAnotherARObject(object, foundExperience.object)
                            }
                        }
                        break;
                    case ActionType.placeFullScreenLottie:
                        this.rendererRef?.placeFullScreenLottie(action.params.lottieUrl, action.params.duration)
                        // case ActionType.customAction:
                        //     this.executeCustomAction(object, data)
                        //     break;
                        break;
                    case ActionType.fadeOut:
                        if (object instanceof THREE.Mesh) {
                            this.ARManager.animateObjectFadeOut(object, 1, 0, action.params.duration)
                        }
                        break;
                    case ActionType.spinObject:
                        const spinParams: SpinParams = {
                            action: "animate",
                            keyPath: "rotation",
                            duration: action.params.duration,
                            repeatCount: action.params.numRotations,
                            fromValue: [0, 0, 0, 0],
                            toValue: [action.params.spinAxis[0], action.params.spinAxis[1], action.params.spinAxis[2], 6.2831]
                        }
                        this.ARManager.spinARObject(object, spinParams)
                        break;
                    case ActionType.lookAtUser:
                        object.lookAt(this.ARManager.getUserPosition())
                        break;
                }
            }

            // Switch cases for both null or non-null cases
            switch (action.actionType) {
                case ActionType.playSound:
                    if (action.params.soundtrackName && action.params.soundtrackUrl) {
                        this.soundManager?.playSound(action.params.soundtrackName)
                    }
                    break;
                case ActionType.updateCounter:
                    this.rendererRef?.updateCounter(action.params.delta)
                    break;
                case ActionType.updateTimer:
                    this.rendererRef?.updateTimer(action.params.delta)
                    break;
                case ActionType.showUIElement:
                    this.rendererRef?.unhideUIElement(action.params.elementID)
                    break;
                case ActionType.hideUIElement:
                    this.rendererRef?.hideUIElement(action.params.elementID)
                    break;
                case ActionType.experienceComplete:
                    this.ARSessionStatusChange(SessionStatus.ExperienceCompleted)
                    break;
                case ActionType.experienceFailed:
                    this.ARSessionStatusChange(SessionStatus.ExperienceNotCompleted)
                    break;
                case ActionType.customAction:
                    this.executeCustomAction(null, data)
                    break;
                case ActionType.pausePublishing:
                    this.triggerManager.pausePublishing()
                    break;
                case ActionType.resumePublishing:
                    this.triggerManager.resumePublishing()
                    break;
                case ActionType.vibrate:
                    this.triggerHaptic()
                    break;
            }
        }
    }

    executeCustomAction(object: THREE.Object3D | null, data: Record<string, any>): void {
        throw new Error('Child class must override this method.');
    }

    triggerHaptic(): void {
        if ('vibrate' in navigator) {
            navigator.vibrate(200);
        } else {
            console.log('Vibration not supported on this device.');
        }
    }
}
