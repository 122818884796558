import {
  ARObjectLoader,
  DelayedARObject,
  NetworkARObjectDict,
} from "../../ExperienceObjects/ExperienceObject";
import { ITemplatesBasics } from "../TemplatesInterface";
import { PlatformManager } from "../CustomTemplate/PlatformManager";
import {
  BaselBurgerShootersConfig,
  BaselBurgerShootersNetworkConfig,
  ShootersItem,
  ShootersListViewTheme,
} from "./BaselBurgerShootersInput";
import { BaselBurgerListItem } from "./basel-burger-shooters-list-component/BaselBurgerShootersListView";
import { ComponentOptions } from "../MenuTemplate/menu-list-component/MenuListView";

interface IBaselBurgerShootersTemplate extends ITemplatesBasics { }

export class BaselBurgerShootersTemplate
  extends PlatformManager
  implements IBaselBurgerShootersTemplate {
  config: BaselBurgerShootersConfig;

  nonTargetNames: string[] = ["bulletObject"];
  listItems: BaselBurgerListItem[] = [];
  selectedIndex: number | null = null;



  /////// External UI
  setShowSelectionScreen: (status: boolean) => void | null = null;

  ///////////// Parse input json //////////////////
  static async parseInput(
    json: Record<string, any>
  ): Promise<BaselBurgerShootersConfig> {
    const shootersNetworkConfig = new BaselBurgerShootersNetworkConfig(json);

    const shootersItems: ShootersItem[] = [];

    for (const networkItem of shootersNetworkConfig.shootersItems) {
      const delayedObject = new DelayedARObject(networkItem.object ?? networkItem.objectImage);
      const item = new ShootersItem(delayedObject, networkItem);
      shootersItems.push(item);
    }

    const config = new BaselBurgerShootersConfig(
      shootersItems,
      shootersNetworkConfig
    );
    return config;
  }

  getCustomExperienceAnalytics(): [Record<string, any>, string] | null {
    const key: string = "experienceAnalytics";
    const analyticsDict: Record<string, any> = {};

    if (this.selectedIndex !== null) {
      let selectedItemName = this.listItems[this.selectedIndex].name;
      analyticsDict["selectedItemName"] = selectedItemName;
      analyticsDict["score"] = this.rendererRef?.fetchScore() ?? -1;
    }

    const score = this.rendererRef?.fetchScore() ?? 0
    localStorage.setItem('HitzScore', score.toString())

    return [analyticsDict, key];
  }

  startExperience(): void {
    this.prepareItemsList();
    this.displaySelectionScreen();
  }

  prepareItemsList() {
    if (this.config === null) {
      return;
    }

    for (const item of this.config.shootersItems) {
      this.listItems.push(
        new BaselBurgerListItem(item.objectName, item.objectImage.fileThumbnail ?? item.objectImage.fileUrl)
      );
    }

    this.setShowSelectionScreen && this.setShowSelectionScreen(true);
  }

  displaySelectionScreen() {
    this.setShowSelectionScreen && this.setShowSelectionScreen(true);
  }

  hideSelectionScreen() {
    this.setShowSelectionScreen && this.setShowSelectionScreen(false);
  }

  async doneButtonClicked(selectedID: string, selectedIndex: number) {
    this.selectedIndex = selectedIndex;
    this.hideSelectionScreen();
    await this.loadRelevantItemAndLaunch(selectedIndex);
  }

  async loadRelevantItemAndLaunch(index: number) {
    if (this.fullExperience === null) {
      return;
    }

    // Load the selected item's object, and insert it to the experience object whose id is "SelectedObject". The settings are taken from the predefined SelectedObject experience object.
    const item = this.config.shootersItems[index];
    const selectedObjectExpIdx = this.fullExperience.experiences.findIndex(
      (experienceObject) => {
        if (experienceObject.id == "SelectedObject") {
          return true;
        } else {
          return false;
        }
      }
    );

    var objectDict: NetworkARObjectDict = item.networkObject ?? item.objectImage
    objectDict.settings = this.fullExperience.experiences[selectedObjectExpIdx].object.userData.settings

    const objectLoader = new ARObjectLoader(objectDict);
    if (objectLoader) {
      this.fullExperience.experiences[selectedObjectExpIdx].object = await objectLoader.loadARObject();
      this.filterOutUnwantedExperienceObjects(index);
      super.startExperience();
    }
  }

  filterOutUnwantedExperienceObjects(index: number) {
    // Filter out all experience objects whose id contains the substring "_removeIfNot_" and then a number different than the selected index
    this.fullExperience.experiences = this.fullExperience.experiences.filter(experience => {
      // Check if the id contains "_removeIfNot_" and extract the number after it
      const match = experience.id.match(/_removeIfNot_(\d+)$/);
      if (match) {
        const numberPart = parseInt(match[1], 10);
        // Remove if the number is not equal to 'index'
        return numberPart === index;
      }
      // If no "_removeIfNot_" is found, keep the entry
      return true;
    });
  }
}
