export enum LocalizationKeys {
    IntroStartButton = "IntroStartButton",
    IntroPageLoadingText = "IntroPageLoadingText",
    RegistrationSubmitButton = "RegistrationSubmitButton",
    RegistrationSkipButton = "RegistrationSkipButton",
    RegistrationFullName = "RegistrationFullName",
    RegistrationDisplayName = "RegistrationDisplayName",
    RegistrationPhoneNumber = "RegistrationPhoneNumber",
    RegistrationEmail = "RegistrationEmail",
    RegistrationConsent = "RegistrationConsent",
    RegistrationPrizesButton = "RegistrationPrizesButton",
    RegistrationSelectImageButton = "RegistrationSelectImageButton",
    WelcomeContinueButton = "WelcomeContinueButton",
    WelcomePrizesButton = "WelcomePrizesButton",
    ExperiencePageStartButton = "ExperiencePageStartButton",
    ExperiencePageLoadingText = "ExperiencePageLoadingText",
    ExitModalText = "ExitModalText",
    ExitModalOkButton = "ExitModalOkButton",
    ExitModalCancelButton = "ExitModalCancelButton",
    PostGameRegistrationButton = "PostGameRegistrationButton",
    PostGameRegistrationText = "PostGameRegistrationText",
    PostGameTryAgainButton = "PostGameTryAgainButton",
    SelectionScreenDoneButton = "SelectionScreenDoneButton"
}