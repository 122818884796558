import { useContext, useEffect, useState } from "react"
import { MainWrapper } from "./styled"
import { AppStore } from "../../../app-store/store"
import { SocialShareButtons } from "../../ShareButton"
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient"
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys"
import { ENV } from "../../../Configs/ServerConfig"
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from "../../../localization/LocalizationKeys"
import AppButton from "../../../UIElements/button/AppButton"
import { LoadingPage } from "../loading-page/LoadingPage"
import { LottieNames } from "../experience-page/ExperiencePage"
import { RequestType } from "../../../Managers/ServerManager"


enum BrowserType {
    Chrome = "Chrome",
    SamsungInternet = "SamsungInternet",
    Unknown = "Unknown"
}

export const IntroScreen = () => {

    // const [isLoadingIntroData, setIsLoadingIntroData] = useState<boolean>(true)
    const [isSupported, setIsXrSupported] = useState<boolean | null>(null)
    const [browserType, setBrowserType] = useState<BrowserType | null>(null)
    const [showPolicy, setShowPolicy] = useState<boolean>(true)
    const { loading, handleIntroPageComplete, introDescription, introTitle, introLogoUrl, globalMetadata, urlParams } = useContext(AppStore)

    const { t } = useTranslation();

    useEffect(() => {
        const policyAccepted = localStorage.getItem('policyAccepted') ?? false
        Mixpanel.track(AnalyticsKeys.IntroScreen, {
            [AnalyticsEventPropertyKeys.PolicyAccepted]: policyAccepted.toString()
        })

        if (policyAccepted) {
            setShowPolicy(false)
        }
        checkBrowserType()
        checkWebXRSupport()
    }, [])

    useEffect(() => {
        if (browserType !== null && isSupported !== null) {
            Mixpanel.track(AnalyticsKeys.BrowserCompatibility, {
                [AnalyticsEventPropertyKeys.BrowserName]: browserType,
                [AnalyticsEventPropertyKeys.XRCompatibility]: isSupported ? "Supported" : "Not Supported",
            })
        }

    }, [browserType, isSupported])

    // useEffect(() => {
    //     console.log("@@@ globalMetadata", globalMetadata)
    //     if (globalMetadata) {
    //         setIsLoadingIntroData(false)
    //     }
    // }, [globalMetadata])

    const checkWebXRSupport = async (): Promise<boolean> => {
        if ("xr" in navigator) {
            const supported = await (navigator as any).xr.isSessionSupported(
                "immersive-ar"
            );
            if (supported) {
                setIsXrSupported(true)
                return
            }
        }
        setIsXrSupported(false)
        return;
    }

    const checkBrowserType = () => {
        const isChrome = /Chrome/.test(navigator.userAgent)
            && /Google Inc/.test(navigator.vendor)
            && !/Samsung/.test(navigator.userAgent);

        const isSamsungInternet = /SamsungBrowser/i.test(navigator.userAgent);

        if (isChrome) {
            setBrowserType(BrowserType.Chrome)
        } else if (isSamsungInternet) {
            setBrowserType(BrowserType.SamsungInternet)
        } else {
            setBrowserType(BrowserType.Unknown)
        }
    }

    const startButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Start"
        })
        handleIntroPageComplete()
    }

    return (
        <MainWrapper>
            {/* If json type is "experience" and it is still loading, then present a loading screen. This is so we can extract the json metadata and display the experience image in the intro page */}
            {/* Ff type==customer then the start button will be disabled until the customer experiences are fetched */}
            {(loading && urlParams && urlParams.type == RequestType.EXPERIENCE) ? (
                <LoadingPage
                    lottieFileName={LottieNames.loading}
                    loadingText={t(LocalizationKeys.IntroPageLoadingText)}
                />
            ) : (
                <>
                    <SocialShareButtons />
                    <header style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/* <img 
                            style={{ width: '220px', height: '220px' }} 
                            src='https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092' 
                            alt="Logo" // Added alt for accessibility
                        /> */}
                        {globalMetadata && globalMetadata?.imageUrl && introLogoUrl ? (
                            <div style={{ position: 'relative', width: '300px', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img
                                    style={{ width: '350px', height: '350px', position: 'absolute' }} // Intro Logo
                                    src={introLogoUrl}
                                    alt="Intro Logo"
                                />
                                <img
                                    style={{ width: '185px', height: '185px', position: 'absolute' }} // Global Metadata Image
                                    src={globalMetadata.imageUrl}
                                    alt="Global Metadata Image"
                                />
                            </div>
                        ) : (
                            <div>
                                <img
                                    style={{ width: '220px', height: '220px' }}
                                    src='https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092'
                                    alt="Logo"
                                />
                            </div>
                        )}
                    </header>
                    {/* <h2>{introTitle}</h2> */}
                    <p>{introDescription}</p>

                    {
                        isSupported === null || browserType === null ?
                            <p>Checking browser support...</p> : null
                    }
                    {
                        // Unknown browser, or samsung internet in production
                        browserType === BrowserType.Unknown ?
                            <p style={{ color: 'red' }}>Please open this page in a <b>Chrome</b> browser on an <b>Android</b> device.</p> : null
                    }
                    {
                        // If good browser but for some reason it is not supported
                        (browserType === BrowserType.Chrome || browserType === BrowserType.SamsungInternet) && !isSupported ?
                            <div>
                                <p style={{ color: 'red' }}>Sorry, this browser does not support webXR technology.</p>
                                <p style={{ color: 'blue' }}>Please open using <b>Chrome</b> browser on an <b>Android</b> device</p>
                            </div> : null
                    }
                    {
                        // Either a chrome browser, or a samsung internet browser in dev mode
                        (browserType === BrowserType.Chrome || browserType === BrowserType.SamsungInternet) && isSupported &&
                        <>
                            <AppButton style={{ margin: 'auto' }} onClick={startButtonTapped} loading={loading}>
                                {/* {t(LocalizationKeys.IntroStartButton)} */}
                                Start
                            </AppButton>
                            {
                                showPolicy &&
                                <p>By clicking 'Start', I agree to the <a target="_blank" href="https://orb-legal.web.app/privacyPolicy">Privacy Policy</a> and <a target="_blank" href="https://orb-legal.web.app/termsOfUse">Terms of Use</a> of Orb Mobile App</p>
                            }
                        </>
                    }
                </>
            )}
        </MainWrapper>
    );


    // return (
    //     <MainWrapper>
    //         <SocialShareButtons />
    //         <header>
    //             <img style={{ width: '220px', height: '220px' }} src='https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092' />
    //             <h2>{introTitle}</h2>
    //             <p>{introDescription}</p>
    //         </header>

    //         {
    //             isSupported === null || browserType === null ?
    //                 <p>Checking browser support...</p> : null
    //         }
    //         {
    //             // Unknown browser, or samsung internet in production
    //             browserType === BrowserType.Unknown ?
    //                 <p style={{ color: 'red' }}>Please open this page in a <b>Chrome</b> browser on an <b>Android</b> device.</p> : null
    //         }
    //         {
    //             // If good browser but for some reason it is not supported
    //             (browserType === BrowserType.Chrome || browserType === BrowserType.SamsungInternet) && !isSupported ?
    //                 <div>
    //                     <p style={{ color: 'red' }}>Sorry, this browser does not support webXR technology.</p>
    //                     <p style={{ color: 'blue' }}>Please open using <b>Chrome</b> browser on an <b>Android</b> device</p>
    //                 </div> : null
    //         }
    //         {
    //             // Either a chrome browser, or a samsung internet browser in dev mode
    //             (browserType === BrowserType.Chrome || browserType === BrowserType.SamsungInternet) && isSupported &&
    //             <>
    //                 <AppButton style={{ margin: 'auto' }} onClick={startButtonTapped} loading={loading}>
    //                     {/* {t(LocalizationKeys.IntroStartButton)} */}
    //                     Start
    //                 </AppButton>
    //                 {
    //                     showPolicy &&
    //                     <p>By clicking 'Start', I agree to the <a target="_blank" href="https://orb-legal.web.app/privacyPolicy">Privacy Policy</a> and <a target="_blank" href="https://orb-legal.web.app/termsOfUse">Terms of Use</a> of Orb Mobile App</p>
    //                 }
    //             </>
    //         }
    //     </MainWrapper >
    // )
}