import { IRegistrationPage } from "../interfaces";
import React, { useContext, useEffect, useState } from 'react';
import {
  FormContainer,
  InnerWrapper,
  Label,
} from './styled';
import { Input, Checkbox, Popover, Button, Modal } from "antd";
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import { StepForwardOutlined, GiftOutlined, CloseOutlined, ExclamationOutlined } from "@ant-design/icons";
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient";
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys";
import { AppStore } from "../../../app-store/store";
import { ExperienceStore } from "../../../app-store/experienceStore";
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from "../../../localization/LocalizationKeys"
import { PageCloseButton } from "../exit-button/styled";
import { CustomizedButton } from "../welcome-page/styled";
import GiftSheetPopover from "../../utils/GiftSheet";
import { SupportedLanguages } from "../../../TriggerAction/Config";

export interface IRegistrationResponse {
  fullName: string
  nickname: string
  email: string
  phoneNumber: string,
}

const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const RegistrationPage: React.FC<IRegistrationPage> = ({ params, onPageComplete, onPageSkip, showCloseButton = true }) => {

  const { globalTheme, experienceStyling } = useContext(AppStore)

  const [fullName, setFullName] = useState('');
  const [nickname, setNickname] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phonenumberError, setPhonenumberError] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const { handleExitExperience } = useContext(ExperienceStore)
  const { t } = useTranslation();

  useEffect(() => {
    Mixpanel.track(AnalyticsKeys.RegistrationScreen, {});
  }, []);

  useEffect(() => {
    if (fullName && nickname && phonenumber && !phonenumberError && email && !emailError && check && (!params.uploadImage || (params.uploadImage && selectedImage))) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [fullName, nickname, phonenumber, email, check, selectedImage]);

  // useEffect(() => {
  //   setEmailError(false);
  // }, [email]);

  // useEffect(() => {
  //   setPhonenumberError(false);
  // }, [phonenumber]);

  const closeButtonTapped = () => {
    Mixpanel.track(AnalyticsKeys.ButtonTapped, {
      [AnalyticsEventPropertyKeys.buttonID]: "Exit"
    })
    handleExitExperience()
  }

  const registerButtonTapped = () => {
    Mixpanel.track(AnalyticsKeys.ButtonTapped, {
      [AnalyticsEventPropertyKeys.buttonID]: 'Submit',
    });
    handleSubmit();
  };

  const handleSubmit = () => {
    const validateEmail = emailRegex.test(email);
    if (email.length && !validateEmail) {
      setEmailError(true);
    }
    if (phonenumber.length !== 10) {
      setPhonenumberError(true);
    } else {
      const response: IRegistrationResponse = {
        fullName,
        nickname,
        email,
        phoneNumber: phonenumber,
      };

      onPageComplete(response, selectedImage);
    }
  };

  const handleImageSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handlePopoverOpen = () => {
    setPopoverVisible(true);
  };

  const handlePopoverClose = () => {
    setPopoverVisible(false);
  };

  const isValidEmail = (email: string) => {
    // Simple regex for email validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const isValidPhoneNumber = (number: string) => {
    // Regex pattern for validating Israeli phone numbers
    const phonePattern = /^(?:05[0-9]|02|03|04|08|09)-?[0-9]{7}$/;
    return phonePattern.test(number);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Validate email and update error state
    if (!isValidEmail(newEmail)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhoneNumber = e.target.value;
    setPhonenumber(newPhoneNumber);

    // Validate phone number and update error state
    if (!isValidPhoneNumber(newPhoneNumber)) {
      setPhonenumberError(true);
    } else {
      setPhonenumberError(false);
    }
  };

  // const popoverContent = (
  //   <div style={{ textAlign: 'center' }}>
  //     {
  //       params.giftSheetInput && (
  //         <img
  //           src={params.giftSheetInput.imageUrl}
  //           alt="Prize"
  //           style={{ maxWidth: '100%', height: 'auto', marginBottom: '10px' }}
  //         />
  //       )
  //     }
  //     {
  //       params.giftSheetInput &&
  //       <p>{params.giftSheetInput.description}</p>
  //     }
  //   </div>
  // );

  const RegistrationButtons = ({ params }) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '99%', position: 'absolute', bottom: '5%', gap: 10 }}>
      {/* {params.giftSheetInput && (
        <Popover
          content={
            <GiftSheetPopover
              giftSheetInput={params.giftSheetInput}
              onClose={() => handlePopoverClose()}
            />
          }
          trigger="click"
          open={popoverVisible}
          onOpenChange={(popoverVisible) => setPopoverVisible(popoverVisible)}
        >
          <CustomizedButton
            primaryColor={globalTheme.primaryColor}
            secondaryColor={globalTheme.secondaryColor}
            onClick={handlePopoverOpen}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <GiftOutlined style={{ fontSize: '30px' }} />
              <span>{t(LocalizationKeys.RegistrationPrizesButton)}</span>
            </div>
          </CustomizedButton>
        </Popover>
      )} */}
      <CustomizedButton
        primaryColor={globalTheme.primaryColor}
        secondaryColor={globalTheme.secondaryColor}
        onClick={handlePopoverOpen}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <GiftOutlined style={{ fontSize: '30px' }} />
          <span>{t(LocalizationKeys.RegistrationPrizesButton)}</span>
        </div>
      </CustomizedButton>
      <CustomizedButton disabled={disabled} primaryColor={globalTheme.primaryColor} secondaryColor={globalTheme.secondaryColor} onClick={registerButtonTapped}>
        {t(LocalizationKeys.RegistrationSubmitButton)}
      </CustomizedButton>
      {params.skipEnabled && onPageSkip && (
        <CustomizedButton primaryColor={globalTheme.primaryColor} secondaryColor={globalTheme.secondaryColor} onClick={onPageSkip}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StepForwardOutlined style={{ fontSize: '30px' }} />
            <span>{t(LocalizationKeys.RegistrationSkipButton)}</span>
          </div>
        </CustomizedButton>
      )}
    </div>
  );

  return (
    <div style={{ backgroundImage: `url(${params.backgroundImageUrl})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height: '100%', padding: '10px 5px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', margin: 'auto', position: 'relative' }}>
      {showCloseButton && <PageCloseButton onClick={closeButtonTapped} shape="circle" icon={<CloseOutlined />} />}
      {
        !params.backgroundImageUrl && <h2 style={{ marginTop: 10 }}>Registration Form</h2>
      }
      {popoverVisible &&
        <Modal
          className="custom-modal"
          open={true}
          centered
          onCancel={handlePopoverClose}
          footer={null}
          closeIcon={null}

        >
          <GiftSheetPopover
            giftSheetInput={params.giftSheetInput}
            onClose={() => handlePopoverClose()}
          />
        </Modal>
      }
      <InnerWrapper style={{ height: params.backgroundImageUrl ? '35%' : 'auto' }}>
        <FormContainer onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}>
          <Input
            placeholder={t(LocalizationKeys.RegistrationFullName)}
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            dir={
              (experienceStyling?.language === SupportedLanguages.Arabic ||
                experienceStyling?.language === SupportedLanguages.Hebrew)
                ? 'rtl'
                : 'ltr'
            }
          />
          <Input
            placeholder={t(LocalizationKeys.RegistrationDisplayName)}
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            dir={
              experienceStyling?.language === SupportedLanguages.Arabic ||
                experienceStyling?.language === SupportedLanguages.Hebrew
                ? 'rtl'
                : 'ltr'
            }
          />
          <Input
            type="number"
            placeholder={t(LocalizationKeys.RegistrationPhoneNumber)}
            value={phonenumber}
            status={phonenumberError ? 'error' : ''}
            // prefix={phonenumberError ? <ClockCircleOutlined /> : null}
            onChange={handlePhoneNumberChange}
            dir={
              experienceStyling?.language === SupportedLanguages.Arabic ||
                experienceStyling?.language === SupportedLanguages.Hebrew
                ? 'rtl'
                : 'ltr'
            }
          />
          <Input
            type="email"
            placeholder={t(LocalizationKeys.RegistrationEmail)}
            value={email}
            status={emailError ? 'error' : ''}
            onChange={handleEmailChange}
            dir={
              experienceStyling?.language === SupportedLanguages.Arabic ||
                experienceStyling?.language === SupportedLanguages.Hebrew
                ? 'rtl'
                : 'ltr'
            }
          />
          {params.uploadImage && (
            <div>
              <Label>{params.uploadImageDescription ?? "Upload Image"}</Label>
              {/* <button
                onClick={() => {
                  const input = document.createElement('input');
                  input.type = 'file';
                  input.onchange = (event) => {
                    const file = (event.target as HTMLInputElement)?.files?.[0];
                    if (file) {
                      setSelectedImage(file);
                    }
                  };
                  input.click();
                }}
              >
                Select File
              </button> */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', padding: '20px' }}>
                <CustomizedButton
                  style={{ margin: 'auto', padding: '10px 20px', borderRadius: '8px' }}
                  primaryColor={globalTheme.primaryColor}
                  secondaryColor={globalTheme.secondaryColor}
                  onClick={() => {
                    const input = document.createElement('input');
                    input.type = 'file';
                    input.onchange = (event) => {
                      const file = (event.target as HTMLInputElement)?.files?.[0];
                      if (file) {
                        setSelectedImage(file);
                      }
                    };
                    input.click();
                  }}
                >
                  {t(LocalizationKeys.RegistrationSelectImageButton)}
                </CustomizedButton>

                {selectedImage && (
                  <img
                    style={{
                      height: '100px',
                      width: '100px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      border: `2px solid black`,
                    }}
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected"
                  />
                )}
              </div>


              {/* <Input
                type="file"
                accept="image/*"
                onChange={handleImageSelection}
                dir={
                  experienceStyling?.language === SupportedLanguages.Arabic ||
                    experienceStyling?.language === SupportedLanguages.Hebrew
                    ? 'rtl'
                    : 'ltr'
                }
              /> */}
            </div>
          )}
          <Checkbox
            checked={check}
            onChange={(event) => setCheck(event.target.checked)}
            style={{
              direction: experienceStyling?.language === SupportedLanguages.Arabic ||
                experienceStyling?.language === SupportedLanguages.Hebrew
                ? 'rtl'
                : 'ltr'
            }}
          >
            {t(LocalizationKeys.RegistrationConsent)}
          </Checkbox>
          {/* <div id="registration-page-scroll-arrow">aaa</div> */}
        </FormContainer>
      </InnerWrapper>
      <RegistrationButtons params={params} />
    </div >
  )
};


