import { LeaderboardEntry } from "../components/pages/leaderboard-page/LeaderboardPage";
import urlJoin from "url-join";
import { customAxios } from "../utils/axios";
import { servicesUrlsByName } from "../Configs/ServerConfig";
import { IRegistrationResponse } from "../components/pages/registration-page/RegistrationPage";
import { ExperienceMetadata } from "../ExperienceObjects/ExperienceObject";

// The request type when fetching json file(s)
export enum RequestType {
  CUSTOMER = "customer",
  EXPERIENCE = "experience",
}
export const convertToRequestType = (value: string): RequestType => {
  // Check if the string value is a valid enum value
  if (Object.values(RequestType).includes(value as RequestType)) {
    return value as RequestType;
  }
  return RequestType.CUSTOMER;
}

// The request source when fetching json file(s)
export enum RequestSource {
  APP = "app",
  QR = "qr",
  SHARE = "share"
}
export const convertToRequestSource = (value: string): RequestSource => {
  // Check if the string value is a valid enum value
  if (Object.values(RequestSource).includes(value as RequestSource)) {
    return value as RequestSource;
  }
  return RequestSource.QR;
}

// The response formats expected from the server when asking for multiple experiences
export interface IExperienceResponse {
  experienceID: string;
  name: string;
  imageUrl: string;
}

export interface ICustomerResponse {
  customerID: string;
  businessDescription: IBusinessDescription;
  experiences: IExperienceResponse[];
}

export interface IBusinessDescription {
  id: string;
  primaryColor: string;
  secondaryColor: string;
  description: string;
  logo: string;
  name: string;
  niche: string;
}

export interface INewUserSession {
  userID: string;
  type: string;
  id: string;
  source: string;
  qrId?: string;
  count: number;
  timestamp: string;
  prevTimestamp: string;
}

export interface IRegisterEndUser {
  fullName: string
  nickname: string
  email: string
  phoneNumber: string
  userID: string
  experienceID: string
}

export class ServerManager {
  static async writeNewUserSession(newUserSession: INewUserSession) {
    try {
      await customAxios(
        servicesUrlsByName.analytics,
        "/end-user",
        "post",
        newUserSession
      );
    } catch (err) {
      throw err;
    }
  }

  static checkIfUserRegisteredToExperience(experienceID: string) {
    return localStorage.getItem(`${experienceID}/user-registration`)
  }

  static async registerEndUser(registrationParams: IRegistrationResponse, userID: string, experienceID: string) {
    // If we previously already registered the user to this specific experience, do not make the call again.
    const expRegistrationFlag = ServerManager.checkIfUserRegisteredToExperience(experienceID)
    if (expRegistrationFlag) {
      return
    }

    // Otherwise, make the API call and store a flag in local storage
    
    const endUserRegistrationParams: IRegisterEndUser = {
      ...registrationParams,
      userID,
      experienceID
    }

    try {
      await customAxios(
        servicesUrlsByName.analytics,
        urlJoin("/end-user", "register"),
        "post",
        JSON.stringify(endUserRegistrationParams)
      );
      // Save flag in localStorage
      localStorage.setItem(`${experienceID}/user-registration`, new Date().toISOString())
    } catch (err) {
      throw err;
    }
  }

  static async silentLogin(userId: string): Promise<string> {
    const data = await customAxios(
      servicesUrlsByName.customersAuth,
      "/native-login",
      "post",
      { userId }
    );
    if (data.success) return data.data.token;
  }

  static async getExperienceJsonFile(id: string): Promise<string> {
    console.log("Fetching JSON file...");
    try {
      const response = await customAxios(
        servicesUrlsByName.files,
        urlJoin("json", id),
        "get"
      );
      
      const data = response.data;
      if (Object.keys(data).length == 0) {
        throw new Error(response.message)
      }
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getExperienceJsonFileFromUrl(url: string): Promise<any> {
    try {
      console.log("ServerManager: Trying to fetch json from url", url);
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(
          `Failed to fetch: ${response.status} ${response.statusText}`
        );
      }
      const json = await response.json();
      return json;
    } catch (error) {
      console.error("Error getting JSON:", error);
      throw error;
    }
  }

  static async getCustomerExperiences(
    customerID: string | null
  ): Promise<ICustomerResponse[]> {
    try {
      // const response = await customAxios(
      //   servicesUrlsByName.files,
      //   `/all-experiences/${customerID}`,
      //   "get"
      // );
      const response = await customAxios(
        servicesUrlsByName.experiences,
        `/experience/customer-experiences/${customerID}`,
        "get"
      );
      const data: ICustomerResponse[] = response.data;
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  static async uploadFile(
    file: File,
    metadata: Record<string, any> = {}
  ): Promise<any> {
    const formData = new FormData();
    console.log("file in uploadfile", file);
    formData.append("file", file);
    formData.append("file", file);
    formData.append("metadata", JSON.stringify(metadata));

    const response = await customAxios(
      servicesUrlsByName.files,
      "templates/upload",
      "post",
      { "Content-Type": "multipart/form-data" }
    );
    // const response = await axios.post(Paths.uploadFilesPath, formData, {
    //     headers: {
    //         "Content-Type": "multipart/form-data"
    //     }
    // })
    return response;
  }

  static async uploadJsonFile(
    data: any = {},
    baseUrl: string,
    endPoint: string
  ) {
    // console.log("Uploading JSON file", jsonString, path)
    try {
      const response = await customAxios(baseUrl, endPoint, "post", data);

      // Check the response status
      if (!response.success) {
        throw new Error("Network response was not ok.");
      }
      // Handle the response data here if needed
      console.log("Upload successful:", response);
      return response;
    } catch (error) {
      console.error("Upload error:", error);
    }
  }

  static async uploadEndUserRegistrationImage(
    image: File,
    metadata: ExperienceMetadata,
    userParams: IRegisterEndUser
  ): Promise<boolean> {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("data", JSON.stringify(userParams));

    const headers = { "Content-Type": "multipart/form-data" }
    const response = await customAxios(
      servicesUrlsByName.files,
      `/upload-enduser-image/${metadata.experienceID}`,
      "post",
      formData,
      headers
    )

    return response.success
  }

  static async writeLeaderboardScore(
    experienceID: string,
    entry: LeaderboardEntry
  ): Promise<LeaderboardEntry[]> {
    let json: Record<string, any> = entry;
    const response = await ServerManager.uploadJsonFile(
      json,
      servicesUrlsByName.analytics,
      `/leaderboard/${experienceID}`
    );
    return response.data;
  }

  static async getLeaderboard(
    experienceID: string
  ): Promise<LeaderboardEntry[]> {
    try {
      const response = await customAxios(
        servicesUrlsByName.analytics,
        `/leaderboard/${experienceID}`,
        "get"
      );
      const data: LeaderboardEntry[] = response.data;
      return data;
    } catch (error) {
      console.error("Error obtaining leaderboard:", error);
      throw error;
    }
  }
}
